<section class="s7-padding-x">
    <h1 class="uk-h1 uk-margin-top">Register for an account.</h1>
    <form class="uk-form-stacked" (ngSubmit)="onSubmit(registerForm.value)" #registerForm="ngForm">
        <div class="form-group">
            <label for="username" class="uk-margin-top uk-display-block">Username</label>
            <input ngModel #name="ngModel" (change)="log(name)" id="username" class="{{ name.touched && !name.valid ? 'uk-form-danger' : '' }} form-control uk-input" type="text" name="name" placeholder="Who are you?" required />
        </div>
        <div class="form-group">
            <label for="email" class="uk-margin-top uk-display-block">Email</label>
            <input ngModel #name="ngModel" id="email" class=" form-control uk-input" type="email" name="email" placeholder="This needs to be legit." required />
        </div>
        <div class="form-group">
            <label for="password" class="uk-margin-top uk-display-block">Password</label>
            <input ngModel #name="ngModel" id="password" class="uk-input" type="password" name="password" placeholder="Pick a good one." required />
        </div>
        <div class="form-group">
            <label for="password_confirmation" class="uk-margin-top uk-display-block">Password (again)</label>
            <input ngModel #name="ngModel" id="password_confirmation" class="uk-input" type="password" name="password_confirmation" placeholder="Once more." required />
        </div>
        <button class="uk-button uk-button-primary uk-margin-top" type="submit">Register</button>
    </form>
</section>

import { CookieService } from 'ngx-cookie-service';
import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import {HttpClient} from '@angular/common/http';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {

    constructor(private titleService: Title, private http:HttpClient, private cookieService: CookieService) {
        this.titleService.setTitle("Register");
    }

    log(x) {
        console.log(x);
    }

    onSubmit(data) {
        this.http.get('http://localhost:8000/api/csrf-cookie', {withCredentials: true})
        .subscribe((result) => {
            console.warn(result);
            this.http.post('http://localhost:8000/api/register', data, {
                withCredentials: true,
                headers: {
                    'X-XSRF-TOKEN': this.cookieService.get('XSRF-TOKEN')
                }
            })
            .subscribe((result) => {
                console.log(result);
            })
        })
    }

    ngOnInit(): void {
    }

}
